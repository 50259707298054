<template>
  <div class="container" id="app-footer">
    <div class="contact-info-container">
      <img class="logo-light" alt="logo-light" src="../assets/logo-light.svg" />
      <div class="contacts-container" id="contacts-container">
        <div class="footer-column">
          <div class="footer-title">
            <h4>Dla klientów</h4>
          </div>
          <div class="footer-items">
            <NuxtLink class="link-item" to="/uslugi"> Usługi </NuxtLink>
            <NuxtLink style="margin-top: 4px;"class="link-item" to="/naprawa"> Naprawa </NuxtLink>
            <NuxtLink style="margin-top: 4px;"class="link-item" to="/serwisy"> Serwisy </NuxtLink>
          </div>
        </div>

        <div class="footer-column">
          <div class="footer-title">
            <h4>Przydatne linki</h4>
          </div>
          <div class="footer-items">
            <a class="link-item" href="/legal/polityka-prywatnosci" target="_blank"> Polityka prywatności </a>
            <a style="margin-top: 4px;"class="link-item" href="/legal/regulamin" target="_blank"> Warunki Korzystania </a>
          </div>
        </div>

        <div class="footer-column" id="kontakt" >
          <div class="footer-title">
            <h4>Skontaktuj się z nami</h4>
          </div>
          <div class="footer-items">
            <div class="icon-and-text">
              <img
                :width="16"
                :height="16"
                src="~/assets/mail.svg"
                alt="email"
                style="margin-right: 8px"
              />
              <a
                class="link-item"
                :href="`mailto:${contactsStore.getContacts.email}`"
              >
                {{ contactsStore.getContacts.email }}
              </a>
            </div>
            <div class="icon-and-text" style="margin-top: 8px;">
              <img
                :width="16"
                :height="16"
                src="~/assets/phone.svg"
                alt="email"
                style="margin-right: 8px"
              />
              <a
                class="link-item"
                :href="`tel:${contactsStore.getContacts.phone}`"
              >
                {{ contactsStore.getContacts.phone }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="links-info-container">
      <small>Copyright ⓒ 2024 ServiceMe. Wszelkie prawa zastrzeżone.</small>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppFooter',
  data() {
    return {
      linksWidth: '28%',
    };
  },
  async setup() {
    const contactsStore = useContactsStore();
    callOnce(contactsStore.init);
    return {
      contactsStore,
    };
  },
  mounted() {
    window.addEventListener('resize', this.getLinksWidth);
  },
  unmounted() {
    window.removeEventListener('resize', this.getLinksWidth);
  },
  methods: {
    getLinksWidth() {
      const contactsContainerWidth =
        document.getElementById('contacts-container')?.offsetWidth;

      if (contactsContainerWidth && contactsContainerWidth !== 0) {
        this.linksWidth = `${contactsContainerWidth}px`;
        return;
      }
      this.linksWidth = '28%';
    },
    onLinkClick(url: string) {
      navigateTo(`/legal/${url}`, {
        open: {
          target: '_blank',
        },
      });
    },
  },
});
</script>

<style scoped>
h4,
h5 {
  color: #fff;
}

small {
  color: #798491;
}

.logo-light {
  max-width: 202px;
  object-fit: contain;
}

.legal-link {
  cursor: pointer;
}

.container {
  background-color: #111528;
  width: 100%;
}

.contact-info-container {
  padding: 32px 186px;
  border-bottom: solid 1px #838a9333;
  display: flex;
  justify-content: space-between;
  transition: ease all 500ms;
}

.link-item {
  font-family: 'Clarity-City';
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #DEE1E3;
  text-decoration: none;
}

.footer-title,
.icon-and-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-items {
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  font-size: 12px;
}

.contacts-container {
  width: 599px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.links {
  min-width: 28%;
}

.links-info-container {
  padding: 20px 186px;
  display: flex;
  justify-content: space-between;
  transition: ease all 500ms;
}

@media screen and (max-width: 628px) {
  .contacts-container {
    flex-direction: column;
    width: 100%;
  }

  .footer-column:not(:last-child) {
    margin-bottom: 40px;
  }

  .logo-light {
    max-width: 170px;
    margin-bottom: 40px;
  }

  .contact-info-container {
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
  }

  .links-info-container {
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
  }

  .links {
    min-width: 0;
    margin-top: 12px;
  }
}

@media screen and (max-width: 890px) and (min-width: 628px) {
  .contact-info-container {
    padding: 32px 20px;
  }

  .links-info-container {
    padding: 32px 20px;
  }
}
</style>
